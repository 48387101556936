<template>
  <div>
    <h3 class="h4 pt-4 border-bottom border-dark">
      {{
        $tc('results.unit.subunit-count', subunits.length, {
          count: subunits.length,
        })
      }}
    </h3>
    <div class="table-responsive">
      <table class="table table-striped result__table">
        <tbody>
          <tr v-for="(subunit, i) in subunits" :key="i">
            <td>
              <a href="#" @click.prevent="$gotoUnit(subunit.acronym)">
                {{ subunit.name }}
              </a>
            </td>
            <td class="text-right">{{ subunit.acronym }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    subunits: {
      type: Array,
      required: true,
    },
  },
};
</script>
