var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-bar"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-100 mr-2"},[_c('p',{staticClass:"search-container icon-right mb-2"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.query),expression:"query",modifiers:{"trim":true}}],staticClass:"form-control form-control-search",attrs:{"type":"search","name":"search","placeholder":_vm.$t('form.query.placeholder'),"autofocus":""},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing){ return; }_vm.query=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-search"}})]),_c('input',{attrs:{"type":"hidden","name":"unit_search","value":"1"}})])]),_c('div',[_c('label',{staticClass:"sr-only",attrs:{"for":"sel-type"}},[_vm._v(" "+_vm._s(_vm.$t('form.type.label'))+" ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.type),expression:"type"}],staticClass:"form-control custom-select form-type",attrs:{"id":"sel-type","name":"type"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.type=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"web"}},[_vm._v(_vm._s(_vm.$t('form.type.web')))]),_c('option',{attrs:{"value":"image"}},[_vm._v(_vm._s(_vm.$t('form.type.images')))])])])]),_c('ul',{staticClass:"nav nav-tabs-light align-items-center",attrs:{"role":"tablist"}},[_vm._l((_vm.filters),function(filter,i){return _c('li',{key:i,staticClass:"nav-item",attrs:{"role":"tab"}},[(_vm.isInternal || filter.display)?_c('a',{class:[
          {
            active:
              filter.id === _vm.searchFilter ||
              (filter.id.length > 0 && _vm.searchFilter.startsWith(filter.id)) ||
              (_vm.searchFilter.length > 0 && filter.id.startsWith(_vm.searchFilter)),
          },
          'nav-link' ],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setFilter(filter.id)}}},[_c('svg',{class:_vm.setTabIcon(filter.id)[0],attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":_vm.setTabIcon(filter.id)[1]}})]),_vm._v(" "+_vm._s(_vm.$t(filter.label))+" ")]):_vm._e()])}),_c('li',{staticClass:"nav.item",attrs:{"role":"tab"}},[_c('a',{staticClass:"nav-link",attrs:{"href":("http://plan.epfl.ch/?q=" + (encodeURI(_vm.query)))}},[_c('svg',{staticClass:"icon feather",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#map-pin"}})]),_vm._v(" "+_vm._s(_vm.$t('form.map'))+" ")])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }