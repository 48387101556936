<template>
  <div
    class="list-group-teaser list-group-item list-group-teaser-container teaser-img-right"
  >
    <div class="list-group-teaser-content">
      <h3 class="h5 mb-1">
        <a :href="link" class="text-break"> {{ title }}</a>
      </h3>
      <p class="text-break text-small text-muted mb-2">{{ link }}</p>
      <p>
        <span class="text-muted text-break" v-html="snippet"></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    link: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    snippet: {
      type: String,
    },
  },
};
</script>
