<template>
  <div>
    <h3 class="h4 pt-4 border-bottom border-dark">
      {{
        $tc('results.unit.people-count', people.length, {
          count: people.length,
        })
      }}
    </h3>
    <table class="table table-striped result__table tablesaw-stack">
      <tbody>
        <tr v-for="(person, i) in people" :key="i">
          <td>
            <a :href="$personLink(person.profile)"
              >{{ person.name }}, {{ person.firstname }}</a
            >
          </td>
          <td>{{ person.position }}</td>
          <td class="text-nowrap">
            <span v-for="(office, i) in person.officeList" :key="i">
              <a :href="`http://plan.epfl.ch/?room==${encodeURI(office)}`">{{
                office
              }}</a
              ><br />
            </span>
          </td>
          <td class="text-nowrap">
            <span v-for="(phone, i) in person.phoneList" :key="i">
              <a :href="`tel:${phone.replace(/ /g, '')}`" class="text-nowrap">
                {{ phone }}</a
              ><br />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    people: {
      type: Array,
      required: true,
    },
  },
};
</script>
