import { eventBus } from '../events/eventBus';
export default {
  // https://dev.to/nkoik/writing-a-very-simple-plugin-in-vuejs---example-8g8

  // The install method will be called with the Vue constructor as the first argument, along with possible options
  install(Vue) {
    // ES6 way of const job = options.job
    // Add $plugin instance method directly to Vue components
    Vue.prototype.$personLink = (profile) =>
      `https://people.epfl.ch/${profile}`;
    Vue.prototype.$gotoUnit = (acronym) => {
      eventBus.$emit('browseUnit', acronym);
    };
  },
};
