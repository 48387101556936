import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loading: false,
    unitSearch: true,
    loggedIn: false,
    isInternal: false,
  },
  mutations: {
    changeLoading(state, loading) {
      state.loading = loading;
    },
    setUnitSearch(state, unitSearch) {
      this.unitSearch = unitSearch;
    },
    setLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    setIsInternal(state, isInternal) {
      state.isInternal = isInternal;
    },
  },
  getters: {
    isLoading: (state) => state.loading,
    getUnitSearch: (state) => state.unitSearch,
    getLoggedIn: (state) => state.loggedIn,
    setIsInternal: (state) => state.isInternal,
  },
});

export default store;
