import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';

import en from './locales/en.json';
import fr from './locales/fr.json';

Vue.use(VueI18n);

export const locales = ['fr', 'en'];

/**
 * Determine current locale
 * 1. Cookie
 * 2. Browser content languages list
 * 3. Browser language
 * 4. Default
 */
export let initialLocale = Cookies.get('locale');

if (!initialLocale) {
  initialLocale = window.navigator.languages
    ? window.navigator.languages.find((val) => {
        return locales.indexOf(val) > -1;
      })
    : window.navigator.language;
}

initialLocale = initialLocale || 'en';

const i18n = new VueI18n({
  fallbackLocale: 'en',
  locale: initialLocale,
  messages: {
    en: en,
    fr: fr,
  },
});

// Hot updates
if (module.hot) {
  module.hot.accept(['./locales/en.json', './locales/fr.json'], function () {
    i18n.setLocaleMessage('en', require('./locales/en.json'));
    i18n.setLocaleMessage('fr', require('./locales/fr.json'));
  });
}

export default i18n;
