import Cookies from 'js-cookie';
import i18n from '@/i18n';

/**
 * Save language and adapt page meta accordingly
 * @param {String} lang The language code
 */
export function setLanguage(locale) {
  Cookies.set('locale', locale);
  document.querySelector('head title').innerHTML = `${i18n.t(
    'meta.name',
  )} &middot; EPFL`;
  document.querySelector('html').setAttribute('lang', locale);
}
