<template>
  <div class="result">
    <h2 class="h3">
      {{ name }} <span class="text-primary">{{ acronym }}</span>
    </h2>
    <ul class="list-unstyled">
      <li v-for="(unit, i) in path" :key="i" :style="{ paddingLeft: `${i}em` }">
        <!-- <a :href="$unitLink(unit.acronym)">{{ unit.name }}&nbsp;<span class="text-primary">{{ unit.acronym }}</span></a> -->
        <a href="#" @click.prevent="$gotoUnit(unit.acronym)"
          >{{ unit.name }}
          <span class="text-primary">{{ unit.acronym }}</span></a
        >
      </li>
    </ul>

    <div class="row">
      <div class="col-md-6">
        <template v-if="head">
          <h3 class="h4 pt-4 border-bottom border-dark">
            {{ $t('results.unit.head') }}
          </h3>
          <div>
            <a v-if="head.profile" :href="$personLink(head.profile)">
              {{ head.name }}, {{ head.firstname }}
            </a>
            <template v-else> {{ head.name }}, {{ head.firstname }} </template>
          </div>
        </template>
      </div>
      <div class="col-md-6">
        <template v-if="url">
          <h3 class="h4 pt-4 border-bottom border-dark">
            {{ $t('results.unit.url') }}
          </h3>
          <div>
            <a :href="url">{{ url }}</a>
          </div>
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <template v-if="address">
          <h3 class="h4 pt-4 border-bottom border-dark">
            {{ $t('results.unit.address') }}
          </h3>
          <address>
            <span v-for="(line, i) in address" :key="i">{{ line }} <br /></span>
          </address>
        </template>
      </div>
      <div class="col-md-6">
        <template v-if="faxes">
          <h3 class="h4 pt-4 border-bottom border-dark">
            {{ $t('results.unit.fax') }}
          </h3>
          <p>
            <span v-for="(line, i) in faxes" :key="i">{{ line }}<br /></span>
          </p>
        </template>
      </div>
    </div>
    <ResultUnitPeople v-if="people" v-bind:people="people" />
    <ResultUnitSubunits v-if="subunits" v-bind:subunits="subunits" />

    <div v-if="adminData">
      <h3 class="h4 pt-4 border-bottom border-dark">
        {{ $t('results.unit.admin-data.label') }}
      </h3>
      <ul class="list-unstyled">
        <li v-for="(url, label, i) in adminData" :key="i">
          <a :href="url">{{ $t('results.unit.admin-data.' + label) }} </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ResultUnitPeople from 'components/ResultUnitPeople';
import ResultUnitSubunits from 'components/ResultUnitSubunits';

export default {
  inheritAttrs: false,

  props: {
    acronym: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    code: Number,
    profile: String,
    head: Object,
    people: Array,
    subunits: Array,
    path: Array,
    address: Array,
    url: String,
    faxes: Array,
    adminData: Object,
  },

  components: {
    ResultUnitPeople,
    ResultUnitSubunits,
  },
};
</script>
