<template>
  <div
    class="list-group-teaser list-group-item list-group-teaser-container teaser-img-right"
  >
    <div class="list-group-teaser-thumbnail" v-if="thumbnail">
      <a :href="link">
        <picture>
          <img :src="thumbnail" class="img-fluid" />
        </picture>
      </a>
    </div>
    <div class="list-group-teaser-content">
      <h3 class="h5 mb-1">
        <a :href="link"> {{ title }}</a>
      </h3>
      <p class="text-break text-small text-muted mb-2">{{ formattedUrl }}</p>
      <p>
        <span class="text-muted text-break">{{ text }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    link: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    formattedUrl: {
      type: String,
      default() {
        return this.link;
      },
    },
    snippet: {
      type: String,
    },
    bodyLines: {
      type: Array,
    },
    pagemap: {
      type: Object,
    },
    image: {
      type: Object,
    },
  },

  computed: {
    thumbnail() {
      if (this.pagemap) {
        return (
          this.pagemap.cse_thumbnail &&
          this.pagemap.cse_thumbnail[0] &&
          this.pagemap.cse_thumbnail[0].src
        );
      } else if (this.image) {
        return this.image.source;
      }
      return '';
    },

    text() {
      if (this.snippet) {
        return this.snippet;
      } else if (this.bodyLines && this.bodyLines[0]) {
        return this.bodyLines[0].title;
      }
      return '';
    },
  },
};
</script>
