import Vue from 'vue';
export const eventBus = new Vue();
eventBus.$on('browseUnit', function (acronym) {
  let app = eventBus.app;
  app.clear();
  app.setBrowseUnit(true);
  app.searchFilter = 'unit';

  // does not work
  if (acronym !== app.searchQuery) {
    app.setQuery(acronym); // query is modified and it launches search again without singleUnit and extendedSearch
  } else {
    app.search();
  }
});
