<template>
  <div class="list-group-item list-group-item-borderless">
    <div class="list-group-teaser-content">
      <p class="tag tag-sm bg-light border-light mb-2">
        <strong>
          {{ $tc(`form.semantic.filters.${type}`, 1) }}
        </strong>
      </p>
      <h3 class="h5 my-1">
        <a :href="link">
          {{ title }}
        </a>
      </h3>
      <p class="text-small text-muted text-break mb-2">{{ link }}</p>
      <p class="text-sm mb-0" v-if="date">
        <svg class="icon feather align-baseline" aria-hidden="true">
          <use xlink:href="#calendar"></use>
        </svg>
        <span class="align-text-bottom">
          {{ $t('results.published-in') }} {{ date }}
        </span>
      </p>
      <p class="text-sm mb-0 text-truncate" v-if="published_in">
        <svg class="icon feather align-baseline" aria-hidden="true">
          <use xlink:href="#book"></use>
        </svg>
        <span class="align-text-bottom">
          {{ published_in }}
        </span>
      </p>
      <p class="text-sm mb-0" v-if="video">
        <svg class="icon feather align-baseline" aria-hidden="true">
          <use xlink:href="#video"></use>
        </svg>
        <span class="align-text-bottom ml-1">
          <a :href="video">{{ $t('results.video') }}</a>
        </span>
      </p>
      <p class="text-sm mb-0" v-if="authors && authors.length">
        <svg class="icon feather align-baseline" aria-hidden="true">
          <use xlink:href="#users"></use>
        </svg>
        <span class="sr-only">{{ $t('results.authors') }}</span>
        <span class="align-text-bottom ml-1">
          <template v-for="(author, index) in authors">
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <a :href="author._url"
              ><strong>{{ author.name_display }}</strong> </a
            ><template v-if="index != Object.keys(authors).length - 1"
              >,
            </template>
          </template>
        </span>
      </p>
      <p class="text-sm mb-0" v-if="path && path.length">
        <svg class="icon feather align-baseline" aria-hidden="true">
          <use xlink:href="#layers"></use>
        </svg>
        <span class="sr-only">{{ $t('results.unit-path') }}</span>
        <span class="align-text-bottom ml-1">
          <template v-for="(unit, index) in path">
            <!-- eslint-disable-next-line vue/require-v-for-key -->
            <a :href="unit._url">{{ unit.initials }}</a>
            <template v-if="index != Object.keys(path).length - 1">
              /
            </template>
          </template>
        </span>
      </p>
      <p class="mt-2" v-if="snippet">
        {{ snippet }}
      </p>
      <p v-if="course">
        <span v-html="course"></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    link: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    snippet: {
      type: String,
    },
    type: {
      type: String,
      required: true,
    },
    authors: {
      type: Array,
    },
    path: {
      type: Array,
    },
    date: {
      type: Number,
    },
    published_in: {
      type: String,
    },
    course: {
      type: String,
    },
    video: {
      type: String,
    },
  },
};
</script>
