import { elideTextByWord } from './text';

const maxSnippetLength = 175;

function buildConceptResult(document, locale) {
  return {
    type: document._type,
    link: document._url,
    title: document.name[locale].value,
    snippet: elideTextByWord(
      document._description_medium[locale].value,
      maxSnippetLength,
    ),
  };
}

function buildPersonResult(document, locale) {
  return {
    type: document._type,
    link: document._url,
    title: document.name_display,
    snippet: elideTextByWord(document.biography[locale], maxSnippetLength),
  };
}

function buildPublicationResult(document) {
  return {
    type: document._type,
    link: document._url,
    title: document.title,
    snippet: elideTextByWord(document.abstract, maxSnippetLength),
    date: document.year,
    published_in: document.published_in,
    authors: document.authors.items,
  };
}

function buildCourseResult(document, locale) {
  return {
    type: document._type,
    link: document._url,
    title: `${document.course_code} - ${document.name[locale].value}`,
    snippet: elideTextByWord(
      document._description_medium[locale].value,
      maxSnippetLength,
    ),
  };
}

function buildLectureResult(document, locale) {
  return {
    type: document._type,
    link: document._url,
    title: document.video.title,
    video: document._external_url[locale],
    snippet: elideTextByWord(
      document._description_medium[locale].value,
      maxSnippetLength,
    ),
  };
}

function buildMoocResult(document, locale) {
  return {
    type: document._type,
    link: document._url,
    title: document.title,
    snippet: elideTextByWord(
      document._description_medium[locale].value,
      maxSnippetLength,
    ),
  };
}

function buildUnitResult(document, locale) {
  return {
    type: document._type,
    link: document._url,
    title: document.initials,
    snippet: document.name[locale].value,
    path: document.path,
  };
}

function buildSemanticResults(json, locale) {
  let items = [];

  for (let i = 0; i < json.items.length; i++) {
    let document = json.items[i];
    switch (document._type) {
      case 'concept':
        items.push(buildConceptResult(document, locale));
        break;
      case 'person':
        items.push(buildPersonResult(document, locale));
        break;
      case 'publication':
        items.push(buildPublicationResult(document));
        break;
      case 'course':
        items.push(buildCourseResult(document, locale));
        break;
      case 'lecture':
        items.push(buildLectureResult(document, locale));
        break;
      case 'mooc':
        items.push(buildMoocResult(document, locale));
        break;
      case 'unit':
        items.push(buildUnitResult(document, locale));
        break;
    }
  }
  return items;
}

export { buildSemanticResults };
