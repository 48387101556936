<template>
  <div
    class="alert alert-dark alert-dismissible fade show pl-3 mt-4 col-12 col-lg-8"
    role="alert"
    v-show="showSemanticDisclaimer"
  >
    <strong>
      {{ $t('results.disclaimer.title') }}
    </strong>
    {{ $t('results.disclaimer.message') }}
    <a target="_blank" :href="$t('results.disclaimer.link')">
      {{ $t('results.disclaimer.link-text') }}
    </a>
    <button
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
      @click.prevent="closeSemanticDisclaimer"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      showSemanticDisclaimer: !Cookies.get('semantic-disclaimer'),
    };
  },
  methods: {
    closeSemanticDisclaimer() {
      this.showSemanticDisclaimer = false;
      Cookies.set('semantic-disclaimer', 1, { expires: 365 });
    },
  },
};
</script>
