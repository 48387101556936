<template>
  <header class="header header-light">
    <div class="header-light-content">
      <a class="logo" href="https://www.epfl.ch" @click.prevent="reset">
        <img
          src="https://web2018.epfl.ch/7.0.0/icons/epfl-logo.svg"
          alt="Logo EPFL, École polytechnique fédérale de Lausanne"
          class="img-fluid"
        />
      </a>
      <p class="site-title">
        <a href="/">{{ $t('meta.name') }}</a>
      </p>

      <nav
        class="nav-lang nav-lang-short ml-auto"
        :aria-label="$t('header.change-language')"
      >
        <ul class="p-0">
          <li v-for="(locale, i) in locales" :key="i">
            <span
              v-if="locale === $i18n.locale"
              v-bind:class="locale === 'en' ? 'active pr-0' : 'active'"
              :aria-label="getLabelForLocale(locale)"
            >
              {{ locale.toUpperCase() }}
            </span>
            <a
              v-else
              href="#"
              v-bind:class="locale === 'en' ? 'pr-0' : ''"
              @click.prevent="setLocale(locale)"
              :aria-label="getLabelForLocale(locale)"
            >
              {{ locale.toUpperCase() }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import { setLanguage } from '@/helpers/multilingual';

export default {
  props: ['locales'],

  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      setLanguage(locale);
    },

    getLabelForLocale(locale) {
      let label = this.$t(`header.languages.${locale}`);

      if (locale === this.$i18n.locale) {
        label += ` (${this.$t('header.languages.current')})`;
      }

      return label;
    },

    reset() {
      this.$emit('reset');
    },
  },
};
</script>
