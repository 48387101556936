<template>
  <div class="list-group-teaser list-group-item list-group-teaser-container">
    <div class="list-group-teaser-content">
      <h3 class="h5 mb-1">
        <a href="#" @click.prevent="$gotoUnit(acronym)">{{ acronym }}</a>
      </h3>
      <p class="text-wrap text-muted mb-2">
        <span class="text-small" v-for="(unit, i) in path" :key="i">
          <a href="#" @click.prevent="$gotoUnit(unit)">{{ unit }}</a>
          <span v-if="i < path.length - 1"> / </span>
        </span>
      </p>
      <span class="text-muted">{{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    acronym: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    path: {
      type: Array,
      required: true,
    },
  },
};
</script>
