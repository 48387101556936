<template>
  <a :href="link" class="image">
    <img :src="thumbnail" class="img-block" />
  </a>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    link: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    },
  },

  computed: {
    thumbnail() {
      return this.image.thumbnailLink;
    },
  },
};
</script>
