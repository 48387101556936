<template>
  <div class="result">
    <h3 class="h3">
      <a class="result" :href="$personLink(this.profile)"
        >{{ name }}, {{ firstname }}</a
      >
    </h3>
    <table class="table result__table tablesaw-stack">
      <tbody>
        <tr v-for="(accred, i) in accreds" :key="i">
          <td width="30%">{{ accred.position }}</td>
          <td width="20%">
            <!-- @click.prevent="gotoUnit(accred.acronym)" using events-->
            <a
              v-if="accred.acronym"
              @click.prevent="$gotoUnit(accred.acronym)"
              href="#"
              class="text-nowrap"
            >
              {{ accred.acronym }}
            </a>
          </td>
          <td width="20%">
            <ul v-if="accred.officeList" class="list-unstyled">
              <li v-for="(office, i) in accred.officeList" :key="i">
                <a
                  :href="`http://plan.epfl.ch/?room==${encodeURI(office)}`"
                  class="text-nowrap"
                  >{{ office }}</a
                >
              </li>
            </ul>
          </td>
          <td width="30%">
            <ul v-if="accred.phoneList" class="list-unstyled">
              <li v-for="(phone, i) in accred.phoneList" :key="i">
                <a
                  :href="`tel:${phone.replace(/ /g, '')}`"
                  class="text-nowrap"
                  >{{ phone }}</a
                >
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <a
      v-if="email"
      :href="`mailto:${email}`"
      class="btn btn-primary btn-block"
      >{{ email }}</a
    >
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    firstname: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    profile: {
      type: String,
      required: true,
    },
    email: {
      type: String,
    },
    accreds: {
      type: Array,
    },
  },
};
</script>
