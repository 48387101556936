<template>
  <div class="search-bar">
    <div class="d-flex">
      <div class="w-100 mr-2">
        <p class="search-container icon-right mb-2">
          <input
            v-model.trim="query"
            type="search"
            name="search"
            class="form-control form-control-search"
            :placeholder="$t('form.query.placeholder')"
            autofocus
          />
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-search"></use>
          </svg>
          <input type="hidden" name="unit_search" value="1" />
        </p>
      </div>

      <div>
        <label for="sel-type" class="sr-only">
          {{ $t('form.type.label') }}
        </label>
        <select
          id="sel-type"
          v-model="type"
          name="type"
          class="form-control custom-select form-type"
        >
          <option value="web">{{ $t('form.type.web') }}</option>
          <option value="image">{{ $t('form.type.images') }}</option>
        </select>
      </div>
    </div>

    <ul class="nav nav-tabs-light align-items-center" role="tablist">
      <li v-for="(filter, i) in filters" :key="i" class="nav-item" role="tab">
        <a
          v-if="isInternal || filter.display"
          href="#"
          :class="[
            {
              active:
                filter.id === searchFilter ||
                (filter.id.length > 0 && searchFilter.startsWith(filter.id)) ||
                (searchFilter.length > 0 && filter.id.startsWith(searchFilter)),
            },
            'nav-link',
          ]"
          @click.prevent="setFilter(filter.id)"
        >
          <svg :class="setTabIcon(filter.id)[0]" aria-hidden="true">
            <use :xlink:href="setTabIcon(filter.id)[1]"></use>
          </svg>
          {{ $t(filter.label) }}
        </a>
      </li>
      <li class="nav.item" role="tab">
        <a
          :href="`http://plan.epfl.ch/?q=${encodeURI(query)}`"
          class="nav-link"
        >
          <svg class="icon feather" aria-hidden="true">
            <use xlink:href="#map-pin"></use>
          </svg>
          {{ $t('form.map') }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['searchQuery', 'searchType', 'searchFilter', 'filters'],

  data() {
    return {
      query: this.searchQuery,
      type: this.searchType,
    };
  },

  computed: {
    isInternal: function () {
      return this.$store.state.isInternal;
    },
  },

  watch: {
    searchQuery() {
      this.query = this.searchQuery;
    },

    searchType() {
      this.type = this.searchType;
    },

    query() {
      this.$emit('queryChanged', this.query);
    },

    type() {
      this.$emit('typeChanged', this.type);
    },
  },

  methods: {
    setFilter(filter) {
      this.$emit('filterChanged', filter);
    },
    setTabIcon: function (filter) {
      const icons = {
        all: ['icon feather', '#search'],
        people: ['icon feather', '#user'],
        courses: ['icon feather', '#book-open'],
        publications: ['icon feather', '#book'],
        news: ['icon feather', '#file-text'],
        unit: ['icon feather', '#layers'],
        map: ['icon feather', '#map-pin'],
        inside: ['icon feather', '#lock'],
        semantic: ['icon', '#icon-epfl-science-graph-search'],
      };
      return icons[filter] || ['icon feather', '#search'];
    },
  },
};
</script>
