import Vue from 'vue';

import App from './App.vue';
import i18n, { initialLocale, locales } from './i18n';
import { setLanguage } from './helpers/multilingual';
import linkPlugin from './plugins/linkPlugin';
import store from './store/store';

/* event bus in vue */
Vue.prototype.$eventHub = new Vue();
Vue.prototype.$searchBackend = 'https://search-backend.epfl.ch';
// Vue.prototype.$searchBackend = 'http://localhost:5555';
Vue.prototype.$searchInsideNodeApi = 'https://search-inside-api.epfl.ch';
// Vue.prototype.$searchInsideNodeApi = 'http://localhost:4444';

Vue.prototype.$descriptionLength = 200;
Vue.prototype.$paginationSize = 10;

/**
 * The JSON API will never return more than 100 results, even if more than 100
 * documents match the query, so setting the sum of start + num to a number
 * greater than 100 will produce an error. 200 is the limit for images.
 *
 * See https://developers.google.com/custom-search/v1
 */
Vue.prototype.$lastStartIndexWeb = 91;
Vue.prototype.$lastStartIndexImages = 191;

Vue.use(linkPlugin);

setLanguage(initialLocale);

/**
 * Launch
 */

new Vue({
  i18n,
  store,
  el: '#app',
  render: (createElement) => {
    return createElement(App, {
      props: {
        locales,
      },
    });
  },
});
