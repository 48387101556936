<template>
  <div class="results mt-2">
    <div
      v-if="
        searchQuery &&
        searchQuery.length >= minimumCharsToSearch &&
        !isLoading &&
        !results.length
      "
      class="mt-5"
    >
      {{ $t('results.empty') }}
    </div>

    <div v-if="results.length || promotions.length">
      <div class="list-group mb-4" v-if="promotions.length">
        <SearchResult
          v-for="(promotion, index) in promotions"
          :key="index"
          v-bind="promotion"
        />
      </div>

      <div v-if="layout === 'grid'" class="images-grid">
        <div
          v-for="(result, index) in results"
          :key="index"
          class="images-grid__item"
        >
          <ResultImage v-bind="result" />
        </div>
      </div>

      <ul v-else-if="layout === 'people'" class="list-unstyled">
        <li v-for="(result, index) in results" :key="index" class="mb-5">
          <ResultPerson v-bind="result" />
        </li>
      </ul>

      <div v-else-if="layout === 'unit' && total <= 1">
        <ResultUnit
          v-for="(result, index) in results"
          :key="index"
          v-bind="result"
        />
      </div>

      <div v-else-if="layout === 'unit' && total > 1">
        <ResultMoreUnits
          v-for="(result, index) in results"
          :key="index"
          v-bind="result"
        />
      </div>

      <div v-else-if="layout === 'inside'">
        <ResultInside
          v-for="(result, index) in results"
          :key="index"
          v-bind="result"
        />
      </div>

      <div v-else-if="layout === 'semantic'">
        <ResultSemantic
          v-for="(result, index) in results"
          :key="index"
          v-bind="result"
        />
      </div>

      <div v-else class="list-group">
        <SearchResult
          v-for="(result, index) in results"
          :key="index"
          v-bind="result"
        />
      </div>
    </div>

    <div v-if="isLoading" class="loader"></div>
  </div>
</template>

<script>
import SearchResult from 'components/SearchResult';
import ResultImage from 'components/ResultImage';
import ResultInside from 'components/ResultInside';
import ResultPerson from 'components/ResultPerson';
import ResultUnit from 'components/ResultUnit';
import ResultMoreUnits from 'components/ResultMoreUnits';
import ResultSemantic from 'components/ResultSemantic';

export default {
  props: {
    layout: {
      type: String,
      required: true,
    },
    promotions: {
      type: Array,
    },
    results: {
      type: Array,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    minimumCharsToSearch: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ResultUnit,
    SearchResult,
    ResultImage,
    ResultInside,
    ResultPerson,
    ResultMoreUnits,
    ResultSemantic,
  },
};
</script>
