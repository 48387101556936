<template>
  <div id="app" class="site">
    <!--
    :style="{
      backgroundImage: 'url('+require('./assets/test_bg.png')+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '75px',
      backgroundPosition: '2em 4em'}">
      -->
    <SearchHeader :locales="locales" v-on:reset="reset" />

    <main class="mb-5">
      <SearchBreadcrumb />

      <div class="container">
        <SearchBar
          :filters="filters"
          :searchQuery="searchQuery"
          :searchFilter="searchFilter"
          :searchType="searchType"
          @queryChanged="setQuery"
          @filterChanged="setFilter"
          @typeChanged="setType"
        />

        <div v-if="error.message" class="mt-5">
          <div
            class="alert alert-dismissible fade show"
            :class="error.type"
            role="alert"
          >
            <strong>{{ error.title }}</strong> - {{ error.message }}
          </div>
        </div>
        <div v-else>
          <p class="mt-4" v-if="correctedQuery">
            <i18n path="autocorrect.label" tag="strong">
              <a href="#" @click.prevent="searchQuery = correctedQuery">{{
                correctedQuery
              }}</a>
            </i18n>
          </p>

          <div
            class="d-flex align-items-center text-muted mt-3 mt-lg-5"
            v-if="
              total >= 1 &&
              (this.searchFilter === 'unit' ||
                this.searchFilter === 'people' ||
                this.searchFilter === 'inside')
            "
          >
            <div>
              {{
                $tc('results.count', this.total, {
                  count: parseInt(this.total).toLocaleString(this.$i18n.locale),
                })
              }}
            </div>
          </div>

          <div v-else-if="this.searchFilter === 'semantic'">
            <div class="d-flex align-items-center text-muted mt-3 mt-lg-5">
              <div v-if="total >= 1">
                {{
                  $tc('results.count', this.total, {
                    count: parseInt(this.total).toLocaleString(
                      this.$i18n.locale,
                    ),
                  })
                }}
              </div>
              <div
                v-if="total >= 0 && this.searchQuery.length >= 2"
                class="ml-auto form-inline"
              >
                <div class="form-group">
                  <label for="doctype" class="mr-2">{{
                    $t('form.semantic.label')
                  }}</label>
                  <select
                    id="doctype"
                    class="form-control custom-select"
                    v-model="doctype"
                  >
                    <option value="">
                      {{ $t('form.semantic.filters.all') }}
                    </option>
                    <option value="concept">
                      {{ $tc('form.semantic.filters.concept', 2) }}
                    </option>
                    <option value="course">
                      {{ $tc('form.semantic.filters.course', 2) }}
                    </option>
                    <option value="lecture">
                      {{ $tc('form.semantic.filters.lecture', 2) }}
                    </option>
                    <option value="mooc">
                      {{ $tc('form.semantic.filters.mooc', 2) }}
                    </option>
                    <option value="person">
                      {{ $tc('form.semantic.filters.person', 2) }}
                    </option>
                    <option value="publication">
                      {{ $tc('form.semantic.filters.publication', 2) }}
                    </option>
                    <option value="unit">
                      {{ $tc('form.semantic.filters.unit', 2) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="total >= 1">
              <SemanticSearchDisclaimer />
            </div>
          </div>

          <div
            class="d-flex align-items-center text-muted mt-3 mt-lg-5"
            v-else-if="total >= 1"
          >
            <div>
              {{
                $tc('results.count', this.total, {
                  count: parseInt(this.total).toLocaleString(this.$i18n.locale),
                })
              }}
            </div>
            <div class="ml-auto form-inline">
              <div class="form-group">
                <label for="sort" class="mr-2">{{
                  $t('results.sort.title')
                }}</label>
                <select
                  id="sort"
                  class="form-control custom-select"
                  v-model="sort"
                >
                  <option value="">{{ $t('results.sort.relevance') }}</option>
                  <option value="date">{{ $t('results.sort.date') }}</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="mt-3"
            v-if="total >= 1 && this.searchFilter === 'publications'"
          >
            <PublicationWarning />
          </div>

          <SearchResults
            v-if="searchQuery"
            :searchQuery="searchQuery"
            :promotions="promotions"
            :results="results"
            :total="total"
            :layout="resultsLayout"
            :minimumCharsToSearch="minimumCharsToSearch"
            :isLoading="isLoading"
          />

          <div v-if="!isLoading && hasMore" class="mt-5">
            <button @click.prevent="search" class="btn btn-light btn-block">
              {{ $t('results.more') }}
            </button>
          </div>
        </div>
      </div>
    </main>

    <div class="mt-auto">
      <div class="container mb-2 text-right small">
        <svg class="icon feather" aria-hidden="true">
          <use xlink:href="#lock"></use>
        </svg>
        <a
          href="#"
          v-if="!this.$store.getters.getLoggedIn"
          @click.prevent="login()"
        >
          {{ $t('login.login') }}
        </a>
        <a
          href="#"
          v-if="this.$store.getters.getLoggedIn"
          @click.prevent="logout()"
        >
          {{ $t('login.logout') }}
        </a>
      </div>

      <SearchFooter />
    </div>
  </div>
</template>

<script>
import 'assets/scss/main.scss';

import axios from 'axios';
import queryString from 'query-string';
import _ from 'lodash';

import { elideTextByWord } from './helpers/text';
import { getProfileUrl } from '@/services/people';

import SearchHeader from 'components/SearchHeader';
import SearchBreadcrumb from 'components/SearchBreadcrumb';
import SearchBar from 'components/SearchBar';
import PublicationWarning from 'components/PublicationWarning';
import SemanticSearchDisclaimer from 'components/SemanticSearchDisclaimer';
import SearchFooter from 'components/SearchFooter';
import SearchResults from 'components/SearchResults';
import { eventBus } from './events/eventBus';
import { buildSemanticResults } from './helpers/semantic';

export default {
  components: {
    SearchHeader,
    SearchBreadcrumb,
    SearchBar,
    PublicationWarning,
    SemanticSearchDisclaimer,
    SearchResults,
    SearchFooter,
  },

  props: {
    locales: {
      type: Array,
      required: true,
    },
  },

  /*
   * special case for unit browsing when computing data:
   * if data.acro parameter is present: (1): add q = data.acro, (2) set singleUnit to true
   */
  data() {
    const data = queryString.parse(location.search);

    return {
      searchQuery: data.q || data.acro || '',
      unitQuery: data.acro || '',
      searchType: data.type || 'web',
      filters: [
        {
          id: '',
          label: 'form.filters.all',
          display: true,
        },
        {
          id: 'people',
          label: 'form.filters.people',
          display: true,
        },
        {
          id: 'unit',
          label: 'form.filters.unit',
          display: true,
        },
        {
          id: 'courses',
          label: 'form.filters.courses',
          site: 'edu.epfl.ch',
          display: true,
        },
        {
          id: 'publications',
          label: 'form.filters.publications',
          site: 'infoscience.epfl.ch',
          display: true,
        },
        {
          id: 'news',
          label: 'form.filters.news',
          site: 'actu.epfl.ch/news',
          display: true,
        },
        {
          id: 'semantic',
          label: 'form.filters.semantic',
          display: true,
        },
        {
          id: 'inside',
          label: 'form.filters.inside',
          display: false,
        },
      ],
      searchFilter: data.filter || '',
      doctype: data.doctype || '',
      sort: data.sort || '',
      results: [],
      promotions: [],
      total: 0,
      hasMore: false,
      offset: 0,
      correctedQuery: '',
      minimumCharsToSearch: 2,
      minimumCharsToSearchUnit: 1,
      error: {
        title: null,
        message: null,
        type: null,
      },
      singleUnit: data.acro || false,
      isLoading: false,
    };
  },

  mounted() {
    Tablesaw.init();
    this.updateFavicons(this.searchFilter);
    axios.defaults.baseURL = this.$searchBackend;
    axios
      .get(this.$searchInsideNodeApi + '/auth/check', {
        withCredentials: true,
      })
      .then((response) => {
        if (
          response.data &&
          response.data.login &&
          response.data.login === true
        ) {
          this.$store.commit('setLoggedIn', true);
        }
        if (
          response.data &&
          response.data.internal &&
          response.data.internal === true
        ) {
          this.$store.commit('setIsInternal', true);
        }
      })
      .catch(function () {});
    if (this.searchQuery) {
      this.search(true);
    }
    eventBus.app = this;
  },

  watch: {
    searchQuery() {
      if (this.searchQuery) {
        this.clear();
      } else {
        this.clear(true);
        return;
      }

      if (this.isBrowseUnit()) {
        this.setLoading(true);
        this.search();
        return;
      }
      if (this.searchQuery.length < this.minimumChars()) {
        return;
      }
      this.setLoading(true);

      if (this.searchDebounce) {
        clearTimeout(this.searchDebounce);
      }

      this.searchDebounce = setTimeout(this.search.bind(this), 1000);
    },

    doctype() {
      this.clear();
      this.search();
    },

    sort() {
      this.clear();
      this.search();
    },

    locale() {
      if (this.searchFilter === 'unit' && this.total === 1) {
        this.setBrowseUnit(true);
      }
      this.clear();
      this.search();
    },
  },

  computed: {
    resultsLayout: function () {
      if (this.searchFilter === 'inside') {
        return 'inside';
      }
      if (this.searchFilter === 'semantic') {
        return 'semantic';
      }
      if (this.searchFilter === 'people') {
        return 'people';
      }
      if (this.searchFilter === 'unit') {
        return 'unit';
      }
      if (this.searchType === 'image') {
        return 'grid';
      }
      return 'list';
    },

    locale: function () {
      return this.$i18n.locale;
    },
  },

  methods: {
    minimumChars() {
      // returns min. length of query to be launched w.r.t. crt filter
      return (
        (this.searchFilter === 'unit' && this.minimumCharsToSearchUnit) ||
        this.minimumCharsToSearch
      );
    },

    setLoading(loading) {
      this.isLoading = loading;
      // this.$store.commit('changeLoading', loading);
    },

    setQuery(query) {
      this.searchQuery = query;
    },

    setAcronym(acronym) {
      this.unitQuery = acronym;
    },

    setBrowseUnit(browse) {
      // this.$store.commit('setUnitSearch', !browse);
      this.singleUnit = browse;
    },

    isBrowseUnit() {
      // return !this.$store.unitSearch;
      return this.singleUnit;
    },

    login() {
      window.location =
        this.$searchInsideNodeApi + '/auth/login' + location.search;
    },
    logout() {
      axios
        .get(this.$searchInsideNodeApi + '/auth/logout', {
          withCredentials: true,
        })
        .then((response) => {
          window.location = '/';
        });
    },

    search: function (initial = false) {
      if (!this.searchQuery || this.searchQuery.length < this.minimumChars()) {
        this.setLoading(false);
        return;
      }

      this.setLoading(true);
      this.updateUrl();

      switch (this.searchFilter) {
        case 'inside':
          this.searchInside(initial);
          break;
        case 'semantic':
          this.searchSemantic(initial);
          break;
        case 'people':
          this.searchPeople(initial);
          break;
        case 'unit':
          this.searchUnit();
          this.setBrowseUnit(false);
          break;
        default:
          this.searchGoogle(initial);
          break;
      }
    },

    buildResult(item) {
      let result = {
        link: item._source.url,
        title: item._source.title,
        snippet: item._source.description,
      };
      if (item._source.attachment && item._source.attachment.title) {
        result.title = item._source.attachment.title;
      }

      if (item._source.description) {
        result.snippet = elideTextByWord(
          item._source.description,
          this.$descriptionLength,
        );
      }

      if (item._source.attachment && item._source.attachment.content) {
        result.snippet = elideTextByWord(
          item._source.attachment.content,
          this.$descriptionLength,
        );
      }

      if (
        item.highlight &&
        item.highlight.description &&
        item.highlight.description[0]
      ) {
        result.snippet = item.highlight.description[0];
      }
      if (
        item.highlight &&
        item.highlight['attachment.content'] &&
        item.highlight['attachment.content'][0]
      ) {
        result.snippet = item.highlight['attachment.content'][0];
      }
      return result;
    },

    searchInside() {
      let params = {
        q: `${this.searchQuery}`,
        from: 0,
      };

      if (this.offset) {
        params.from = this.offset;
      }

      axios
        .get(this.$searchInsideNodeApi + '/api/search', {
          params,
          withCredentials: true,
        })
        .then((response) => {
          let data = response && response.data;
          let items = [];
          if (data) {
            for (let i = 0; i < data.hits.hits.length; i++) {
              items.push(this.buildResult(data.hits.hits[i]));
            }
            this.results = [...this.results, ...items];
            this.total = data.hits.total.value;
            this.hasMore = this.total > this.offset + this.$paginationSize;
            if (this.hasMore) {
              this.offset = this.offset + this.$paginationSize;
            }
          }
          this.setLoading(false);
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            window.location =
              this.$searchInsideNodeApi + '/auth/login' + location.search;
          } else {
            this.handleError(error);
          }
        });
    },

    searchSemantic() {
      let params = {
        q: `${this.searchQuery}`,
        limit: this.$paginationSize * 2,
      };

      if (this.doctype) {
        params.doctype = this.doctype;
      }

      axios
        .get('/api/graphsearch', {
          params,
        })
        .then((response) => {
          let data = response && response.data;
          if (data) {
            let items = buildSemanticResults(data, this.locale);
            this.results = [...this.results, ...items];
            this.total = data.result_count;
            this.hasMore = false;
          }

          this.setLoading(false);
        })
        .catch((error) => {
          if (error) {
            this.handleError(error);
          }
        });
    },

    searchUnit() {
      let params = {
        hl: this.locale,
        siteSearch: 'unit.epfl.ch',
      };
      params[this.isBrowseUnit() ? 'acro' : 'q'] = this.searchQuery;

      axios
        .get('/api/unit', {
          params,
        })
        .then((response) => {
          let data = response && response.data;
          if (data) {
            if (data.code) {
              this.results = [data];
              this.total = 1;
            } else if (data.length) {
              this.results = data;
              this.total = data.length;
            }
          }
          this.setLoading(false);
        }, this.handleError);
    },

    searchPeople(initial) {
      axios
        .get('/api/ldap', {
          params: {
            q: this.searchQuery,
            hl: this.locale,
            siteSearch: 'people.epfl.ch',
          },
        })
        .then((response) => {
          let data = response && response.data;
          if (data && data.length) {
            if (!initial && data.length === 1) {
              const userProfile = data[0].profile;
              window.location = getProfileUrl(userProfile);
              return;
            }

            this.results = data;
            this.total = data.length;
          }
          this.setLoading(false);
        }, this.handleError);
    },

    searchGoogle() {
      let params = {
        q: this.searchQuery,
        hl: this.locale,
      };

      if (this.searchFilter) {
        const filter = _.find(this.filters, { id: this.searchFilter });
        const { site } = filter;
        if (site) {
          params.siteSearch = site;
          params.siteSearchFilter = 'i';
        }
      }

      if (this.offset) {
        params.start = this.offset;
      }

      if (this.sort) {
        params.sort = this.sort;
      }

      if (this.searchType !== 'web') {
        params.searchType = this.searchType;
      }

      axios
        .get('/api/cse', {
          params,
        })
        .then((response) => {
          let data = response && response.data;

          if (data && data.items) {
            this.results = [...this.results, ...data.items];
            this.promotions = data.promotions || [];
            this.total = parseInt(data.searchInformation.totalResults, 10);
            this.hasMore = this.googleCseHasMore(data);
            this.correctedQuery = data.spelling && data.spelling.correctedQuery;

            if (this.hasMore) {
              this.offset = data.queries.nextPage[0].startIndex;
            }
          }
          this.setLoading(false);
        }, this.handleError);
    },

    googleCseHasMore(data) {
      const lastStartIndex =
        this.searchType === 'web'
          ? this.$lastStartIndexWeb
          : this.$lastStartIndexImages;

      const hasNextPage = !!(
        data.queries &&
        data.queries.nextPage &&
        data.queries.nextPage[0]
      );

      const isLastStartIndex = this.offset === lastStartIndex;

      return hasNextPage && !isLastStartIndex;
    },

    handleError(error) {
      this.error.title = this.$t('results.errors.500.title');
      this.error.message = this.$t('results.errors.500.message');
      this.error.type = 'alert-danger';
      if (error && error.response && error.response.status === 403) {
        this.error.title = this.$t('results.errors.403.title');
        this.error.message = this.$t('results.errors.403.message');
        this.error.type = 'alert-warning';
        this.$store.commit('setIsInternal', false);
      }
      this.hasMore = false;
      this.setLoading(false);
    },

    updateFavicons(filter) {
      const favicons = {
        'favicon-ico': '.ico',
        'favicon-16': '-16.png',
        'favicon-32': '-32.png',
      };
      for (const favicon in favicons) {
        const fav = document.getElementById(favicon);
        if (filter === 'people') {
          fav.href = '/favicons/people' + favicons[favicon];
        } else if (filter === 'unit') {
          fav.href = '/favicons/units' + favicons[favicon];
        } else {
          fav.href = '/favicons/all' + favicons[favicon];
        }
      }
    },

    updateUrl() {
      this.updateFavicons(this.searchFilter);
      const url = {
        q: this.searchQuery,
      };
      if (this.searchFilter) {
        url.filter = this.searchFilter;
      }
      if (this.doctype) {
        url.doctype = this.doctype;
      }
      if (this.sort) {
        url.sort = this.sort;
      }
      if (this.searchType === 'image') {
        url.type = this.searchType;
      }

      history.replaceState(
        {
          q: this.searchQuery,
          filter: this.searchFilter,
          doctype: this.doctype,
          sort: this.sort,
          type: this.searchType,
        },
        this.searchQuery,
        `${window.location.pathname}?${queryString.stringify(url)}`,
      );
    },

    reset() {
      this.searchQuery = '';
      this.setBrowseUnit(false);
      this.searchFilter = '';
      this.clear(true);
    },

    clear(url = false) {
      this.results = [];
      this.promotions = [];
      this.total = 0;
      this.hasMore = false;
      this.offset = 0;
      this.correctedQuery = '';
      this.error = {
        title: null,
        message: null,
        type: null,
      };

      if (url) {
        history.replaceState({}, '', window.location.pathname);
      }
    },

    setFilter(filter) {
      this.searchFilter = filter;
      this.clear();
      this.search();
    },

    setType(type) {
      this.searchType = type;
      this.clear();
      this.search();
    },
  },
};
</script>
